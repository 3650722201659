import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://dash.poppytool.com/api-dev'
});

instance.interceptors.request.use(
    async (config) => {
        const token = "";
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);


export default instance;